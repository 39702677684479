import React, { Component } from 'react';

class SocialMedia extends Component {
  render() {
    return (
      <div className="social-block">
        <a href="mailto:jenniferenck@mac.com">
          <i className="social-icon fas fa-envelope" />
        </a>
        <a href="https://www.linkedin.com/in/enckjennifer/">
          <i className="social-icon fab fa-linkedin-in" />
        </a>
        <a href="https://github.com/jenniferenck">
          <i className="social-icon fab fa-github" />
        </a>
        <a href="https://angel.co/jennifer-enck?al_content=view+your+profile&al_source=transaction_feed%2Fnetwork_sidebar">
          <i className="social-icon fab fa-angellist" />
        </a>
      </div>
    );
  }
}

export default SocialMedia;

import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal';
import { Container, Row } from 'reactstrap';
import IsScrolling from 'react-is-scrolling';
import './Work.css';

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = { mouseOver: '', moreInfo: '', activeSheet: null };
  }

  // handles mouse over and out individual work sheet
  handleMouseOverAndOut = activeSheet => {
    activeSheet
      ? this.setState({
          activeSheet: activeSheet
        })
      : this.setState({
          activeSheet: null
        });
  };

  handleMoreInfoClick = () => {
    this.setState({
      moreInfo: 'active'
    });
  };

  handleClickAway = () => {
    this.setState({
      moreInfo: ''
    });
  };

  render() {
    const { activeSheet } = this.state;

    const workSheetInfo = {
      theRelish: {
        title: 'The Relish - Internship',
        brief:
          'Working directly with the founder and CTO, I developed new internal tools, including a drap-and-drop, to improve a customer management and content curation tool while also cutting page load times (2x faster!) by refactoring the redux codebase',
        stack: 'React.js, Redux, Firebase, Material UI',
        sites: 'https://www.therelish.com/'
      },
      artistsMat: {
        title: 'The Artists Mat - Current Project',
        brief:
          'I am actively contributing to an early-stage start-up to prepare for an official site launch (coming soon!). Some of my initial contributions have included setting up tooling and frontend testing suite, building the FAQ page and integrating the Google Places API',
        stack: 'babel, eslint, Enzyme, Jest, HTML, CSS, Google Places API',
        sites: 'https://www.artistsmat.com/'
      },
      jobly: {
        title: 'Jobly - Personal Project',
        brief:
          'Fullstack project I built mocking a job application site where users can browse, search and apply for jobs',
        stack:
          'React.js, Node.js, postgreSQL, Enzyme, Jest, Bootstrap, Heroku (deployment)',
        sites: 'https://jobly-frontend-jenenck.herokuapp.com/'
      },
      mineNow: {
        title: 'Mine. Now. - Personal Project',
        brief:
          'Still a work-in-progresss - this application integrates multiple APIs to provide users with shopping recommendations based on their Pinterest boards. Click the link to see the GitHub page and the current state of this project!',
        stack:
          'React.js, React-Router, Pinterest API, Clarifai API, Figma (I designed this myself!)',
        sites: 'https://github.com/jenniferenck/shopping-recommendations'
      }
    };
    return (
      <Container className={`work window col-12 col-md-8`}>
        <Reveal>
          {/* <Row className="col-12 justify-content-center"> */}
          <Row className="col-12 work-preview-container">
            <div className="work-stack">
              <div className="work-preview-sheet jobly">
                <img
                  onMouseEnter={() => this.handleMouseOverAndOut('jobly')}
                  // onMouseLeave={() => this.handleMouseOverAndOut(false)}
                  className="work-preview-image jobly"
                  src="../images/jobly-jobs.png"
                  alt="hack or snooze project"
                />
                <img
                  onMouseEnter={() => this.handleMouseOverAndOut('jobly')}
                  // onMouseLeave={() => this.handleMouseOverAndOut(false)}
                  className="work-preview-image jobly"
                  src="../images/jobly-home.png"
                  alt="hack or snooze project"
                />
                <img
                  onMouseEnter={() => this.handleMouseOverAndOut('jobly')}
                  // onMouseLeave={() => this.handleMouseOverAndOut(false)}
                  className="work-preview-image jobly"
                  src="../images/jobly-companies.png"
                  alt="hack or snooze project"
                />
              </div>

              <div className="work-preview-sheet theRelish">
                <img
                  onMouseEnter={() => this.handleMouseOverAndOut('theRelish')}
                  // onMouseLeave={() => this.handleMouseOverAndOut(false)}
                  className="work-preview-image theRelish"
                  src="../images/where-fans-go.png"
                  alt="the relish"
                />
                <img
                  onMouseEnter={() => this.handleMouseOverAndOut('theRelish')}
                  // onMouseLeave={() => this.handleMouseOverAndOut(false)}
                  className="work-preview-image theRelish"
                  src="../images/whats-trending.png"
                  alt="the relish"
                />
                <img
                  onMouseEnter={() => this.handleMouseOverAndOut('theRelish')}
                  // onMouseLeave={() => this.handleMouseOverAndOut(false)}
                  className="work-preview-image theRelish"
                  src="../images/download-app.png"
                  alt="the relish"
                />
              </div>
              <div className="work-preview-sheet shoply">
                <img
                  onMouseEnter={() => this.handleMouseOverAndOut('mineNow')}
                  // onMouseLeave={() => this.handleMouseOverAndOut(false)}
                  className="work-preview-image shoply"
                  src="../images/user-search.png"
                  alt="the shoply project"
                />
                <img
                  onMouseEnter={() => this.handleMouseOverAndOut('mineNow')}
                  // onMouseLeave={() => this.handleMouseOverAndOut(false)}
                  className="work-preview-image shoply"
                  src="../images/user-search-results.png"
                  alt="the shoply project"
                />
              </div>
            </div>

            {/* </div> */}
          </Row>
        </Reveal>

        <Row className="work-info-section col-12">
          {activeSheet ? (
            <Reveal>
              <div className="project-info col-12">
                <h4 className="mb-1">{workSheetInfo[activeSheet].title}</h4>
                <p>{workSheetInfo[activeSheet].brief}</p>
                <h5>Technologies Deployed:</h5>
                <p>{workSheetInfo[activeSheet].stack}.</p>
                <small>
                  <a
                    className="visit-site"
                    href={workSheetInfo[activeSheet].sites}
                  >
                    Visit site.
                  </a>
                </small>
              </div>
            </Reveal>
          ) : (
            <Reveal>
              <h3 className="work-intro-blurb">
                ABOVE ARE JUST A FEW OF MY RECENT PROJECTS INVOLVING SOME OF THE
                MOST RECENT TECHNOLOGIES (CLICK TO READ MORE).
              </h3>
            </Reveal>
          )}
        </Row>
      </Container>
      // </Slide>
    );
  }
}

export default IsScrolling(Portfolio);

import React, { Component } from 'react';
import Zoom from 'react-reveal/Zoom';
import { Container, Row } from 'reactstrap';
// import { Route } from 'react-router-dom';

import './Home.css';

class Home extends Component {
  render() {
    return (
      <Container className={`home window col-12 scroll-item`}>
        <Row className="justify-content-center align-items-center col-12 home-contents">
          <Zoom>
            {/* <h1 className="slide-title">jennifer. enck.</h1> */}
            <div className="title-description">
              <div className="text"> web developer.</div>
              <div className="text"> react enthusiast.</div>
              <div className="text"> san francisco. </div>
            </div>
          </Zoom>
        </Row>
      </Container>
    );
  }
}

export default Home;

import React, { Component } from 'react';

import './App.css';

import NavBar from './NavBar/index';
import Home from './Home/index';
import About from './About/index';
import Work from './Work/index';
import Contact from './Contact/index';
import Scroll from './Scroll/index';
import SiteLoadAnimation from './SiteLoadAnimation/index';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { scrollActive: false, currentPage: 'home' };
  }

  handleDisplayScroll = () => {
    console.log('show scroll');
    this.setState({
      scrollActive: true
    });
  };

  handleHideScroll = () => {
    this.setState({
      scrollActive: false
    });
  };

  handleCurrentPage = currentPage => {
    this.setState({
      currentPage: currentPage
    });
  };

  render() {
    return (
      <div className="App scroll-container">
        <SiteLoadAnimation />
        <NavBar
          currentPage={this.state.currentPage}
          handleDisplayScroll={this.handleDisplayScroll}
          handleHideScroll={this.handleHideScroll}
        />

        <Scroll
          currentPage={this.state.currentPage}
          displayScroll={this.state.scrollActive}
          handleDisplayScroll={this.handleDisplayScroll}
          handleHideScroll={this.handleHideScroll}
        />

        <Home
          title="Home Page"
          id="home"
          removeLogo={this.removeNameLogo}
          handleCurrentPage={this.handleCurrentPage}
        />
        <About
          title="About Page"
          id="about"
          displayLogo={this.displayNameLogo}
          handleCurrentPage={this.handleCurrentPage}
        />
        <Work
          title="Work Page"
          id="work"
          handleCurrentPage={this.handleCurrentPage}
        />
        <Contact
          title="Contact Page"
          id="contact"
          handleCurrentPage={this.handleCurrentPage}
        />
      </div>
    );
  }
}

export default App;

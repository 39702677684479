import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import Reveal from 'react-reveal/Reveal';
import './Contact.css';
import SocialMedia from '../SocialMedia/index';

class Contact extends Component {
  render() {
    return (
      <Container className="contact window col-12">
        <Row className="col-12 col-md-8 mt-4 p-0">
          <h1 className="col-12 p-0 text-left contact-intro">
            IF YOU HAVE THE TIME, PLEASE DROP A LINE - I'D LOVE YOU HEAR FROM
            YOU.
          </h1>
        </Row>

        <Row className="h4 col-12 col-md-8 pl-0 pb-4 contact-click-items">
          <Reveal effect="fadeInUp">
            <SocialMedia />
          </Reveal>
        </Row>
      </Container>
    );
  }
}

export default Contact;

import React, { Component } from 'react';
import Slide from 'react-reveal/Slide';
// import Reveal from 'react-reveal/Reveal';
import { Container, Row } from 'reactstrap';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = { mouseOver: '', moreInfo: '' };
  }

  render() {
    return (
      <Container id="about" className="About window col-12 scroll-item">
        <Row className="align-items-start justify-content-around about-block">
          <Slide left>
            <div className="col-12 col-md-7 m-3">
              <p className="section h4 text-left">Tl; dr.</p>
            </div>
            <div className="col-11 col-md-7 m-1">
              <p className="text-left">
                Small town Georgia-raised, now full-stack developer based in San
                Francisco.
              </p>
              <p className="text-left">
                What differentiates me from other developers is my ability to
                bring a big-picture perspective to my approach, focusing on
                understanding the end-user and the business needs as a whole.
              </p>
            </div>
          </Slide>

          <Slide right>
            <div className="col-12 col-md-7 m-3">
              <p className="section h4 text-left">Gimme the details.</p>
            </div>
            <div className="col-11 col-md-7 m-1">
              <p className="text-left">
                With a larger part of my background in growth consulting and
                working with start-ups, I have been programming for a little
                over a year now, recently completing a full-stack web
                development program at{' '}
                <a href="https://www.rithmschool.com/">Rithm School</a> followed
                by an internship with a sports media start-up called{' '}
                <a href="http://www.therelish.com/">The Relish.</a>. I am
                currently contributing to a recently-founded, jujitsu-events
                start-up.
              </p>
              <p className="text-left">
                My experience is centered around some of the most popular and
                relevant technologies/frameworks including <i>React.js</i>,
                <i> Redux</i>, <i>Node.js</i>, <i>Python</i>, <i>SQL</i>, and
                more.
              </p>
              <p className="text-left">
                I have developed full-stack web applications, designed and
                product-managed internal tools, set-up test-suites, and
                refactored pre-exisitng codebases, enabling start-ups work more
                efficiently. I am fascinated in the user experience and
                constantly teaching myself new design techniques.
              </p>
              <p className="text-left">
                Keep scrolling for more details on some of my recent projects.
              </p>
            </div>
          </Slide>
        </Row>
      </Container>
    );
  }
}

export default About;

import React, { Component } from 'react';
import Zoom from 'react-reveal/Zoom';
import './SiteLoadAnimation.css';

class SiteLoadAnimation extends Component {
  render() {
    return (
      <div className="page-loader-screen ripple">
        <Zoom>
          <h1 className="initials">jennifer. enck.</h1>
        </Zoom>
      </div>
    );
  }
}

export default SiteLoadAnimation;

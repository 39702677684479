import React, { Component } from 'react';
import { Nav } from 'reactstrap';
import { Link } from 'react-scroll';
// look into animation from ^^^^

import './NavBar.css';

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = { isActive: false };
  }

  handleNavClick = () => {
    console.log('click happening!');
    this.setState(st => ({
      isActive: !st.isActive
    }));
  };

  render() {
    return (
      <div>
        <Nav>
          <li className="nav-item">
            <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              // current issues with offset - 0 scrolls to exact center of window but doesnt detect for active link if scrolling
              offset={0}
              duration={500}
              onSetActive={this.props.handleDisplayScroll}
              // onSetInactive={this.props.handleHideScroll}
            >
              about.
            </Link>
          </li>
          <li className="nav-item">
            <Link
              activeClass="active"
              to="work"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onSetActive={this.props.handleDisplayScroll}
            >
              work.
            </Link>
          </li>
          <li className="nav-item contact-item">
            <Link
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              onSetActive={this.props.handleDisplayScroll}
            >
              contact.
            </Link>
          </li>
        </Nav>
      </div>
    );
  }
}

export default NavBar;

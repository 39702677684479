import React, { Component } from 'react';
import { Link } from 'react-scroll';
import './Scroll.css';

class Scroll extends Component {
  render() {
    return (
      <>
        {this.props.displayScroll ? (
          <Link
            className="scroll"
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onSetActive={this.props.handleHideScroll}
            onSetInactive={this.props.handleDisplayScroll}
          >
            back to top.
          </Link>
        ) : // <ScrollTo selector={'.home'}>
        //   <div className="scroll">back to top.</div>
        // </ScrollTo>
        null}
      </>
    );
  }
}

export default Scroll;
